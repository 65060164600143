import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import {Button, Icon, Modal, Radio, Tooltip} from 'antd';
import {Trans, t} from '@lingui/macro';
import CustomersSelect from '../../project/CustomersSelect';
import withdataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import ButtonFetch from '../../fetch/ButtonFetch';
import InfoAttribute from '../../general/InfoAttribute';
import Spinner from '../../graphics/Spinner';
import { merge } from '../../../lib/object';
import { TRADE } from '../../../constants/trade';
import CartOrderInfoPageForm from '../../cart/cartOrderInfo/CartOrderInfoPageForm';
import ChildPriceLevelSelect from '../../project/ChildPriceLevelSelect';
import { OrderDetail } from '../../../constants/propTypesDefinitions';
import { navigateToParametrized } from '../../../lib/url';
import locationHOC from '../../locationProvider/locationHOC';
import { QUERY_PARAMS, ROUTES } from '../../../constants/navigation';
import { getInputOnChangeEventValue } from '../../../lib/project';
import ManufacturersSelect from '../../project/ManufacturersSelect';
import { createFetchCustomerDetails, createFetchOrderFromIncoming } from '../../../backend/apiCalls';
import { stopPropagation } from '../../../lib/interaction';
import { numberOrNull } from '../../../lib/number';

/**
 * @dusan
 */


class OrderFromIncoming extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    onOpen = () => {
        this.setState({visible: true});
    };

    onClose = () => {
        this.setState({visible: false});
    };

    onFinish = (res) => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.ORDER_DETAILS, {[QUERY_PARAMS.ID_ORDER]: res});
    };

    render() {
        const {orderDetails = {}} = this.props;
        const {visible} = this.state;

        return <div onClick={stopPropagation} onKeyDown={stopPropagation}>
            <Button onClick={this.onOpen}>
                <Icon type="retweet"/>
                <span className="ml-2"><Trans>Objednať</Trans></span>
            </Button>
            <Modal
                width="50rem"
                visible={visible}
                onCancel={this.onClose}
                destroyOnClose={true}
                keyboard={true}
                closable={true}
                maskClosable={false}
                title={<Trans>Vyvoriť odoslanú objednávku z prijatej</Trans>}
                footer={null}
            >
                <CreateOrderFormWrapped
                    onClose={this.onClose}
                    onSubmitFinish={this.onFinish}
                    finalOrder={orderDetails}
                />
            </Modal>
        </div>;
    }

}

export default locationHOC(OrderFromIncoming);

class CreateOrderForm extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.FETCH_DATA]: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        finalOrder: OrderDetail.isRequired,
    };

    constructor(props) {
        super(props);
        const finalOrder = props.finalOrder != null ? props.finalOrder : {};
        const finalCustomer = finalOrder.customer != null ? finalOrder.customer : {};
        const finalUser = finalOrder.user != null ? finalOrder.user : {};
        const finalAddress = finalOrder.delivery_address != null ? finalOrder.delivery_address : {};

        this.state = {
            loadingCustomer: false,
            customer: null,
            customerId: undefined,
            manufacturerId: undefined,
            priceLevelId: undefined,
            useFullQuantity: false,
            availableInAdvance: null,
            orderParams: {
                trade: TRADE.BUY,
                isProxy: false,
                finalOrderRef: finalOrder.dealer_reference,
                finalCustomerName: finalCustomer.name,
                finalUserName: finalUser.name,
                finalUserEmail: finalUser.e_mail,
                finalUserPhone: finalUser.phone,
                finalUserLanguage: finalUser.id_language,
                finalAddressStreet: finalAddress.street,
                finalAddressZipCode: finalAddress.zip_code,
                finalAddressCity: finalAddress.city,
                finalAddressCountryId: finalAddress.id_country
            },
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {customerId: prevCustomerId} = prevState;
        const {customerId} = this.state;

        if(customerId != null && prevCustomerId != customerId)
            this.fetchCustomer();    
    }

    onOrderParamsChange = (newParams) => {
        const {orderParams} = this.state;
        const paramsChecked = merge(orderParams, newParams);
        this.setState({
            orderParams: paramsChecked,
        });
    }

    onCustomerChange = (newCustomerId) => {
        this.setState({
            customerId: newCustomerId,
            priceLevelId: newCustomerId,
        });
    };

    onPriceLevelChange = (newPriceLevelId) => {
        this.setState({
            priceLevelId: newPriceLevelId,
        });
    };

    onManufacturerChange = (newManufId) => {
        this.setState({
            manufacturerId: newManufId,
        });
    };

    onUseFullQuantityChange = (ev) => {
        const newVal = getInputOnChangeEventValue(ev);
        this.setState({
            useFullQuantity: newVal,
        });
    };

    onAvailAdvanceChange = (ev) => {
        const newVal = getInputOnChangeEventValue(ev);
        const valChecked = numberOrNull(newVal);
        this.setState({
            availableInAdvance: valChecked,
        });
    };
    
    fetchCustomer = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_DATA];
        const {customerId} = this.state;
        this.setState({
            loadingCustomer: true,
        });
        
        fetchHandler(
            createFetchCustomerDetails(),
            {id: customerId},
            (result) => {
                this.setState({
                    customer: result,
                    loadingCustomer: false,
                });
            },
        )
    }

    render() {
        const {loadingCustomer, customer, orderParams, customerId, priceLevelId, 
            useFullQuantity, availableInAdvance, manufacturerId} = this.state;
        const {onClose, onSubmitFinish, finalOrder} = this.props;

       const disabled = customerId == null ||
            orderParams.invoiceAddressId == null || orderParams.deliveryAddressId == null || 
            orderParams.transportTypeId == null || orderParams.paymentMethodId == null;

        return loadingCustomer ? <Spinner/> : <div>
            <InfoAttribute
                attributeTitle={<Trans>Dodávateľ:</Trans>}
                attributeValue={
                    <CustomersSelect
                        onChange={this.onCustomerChange}
                        value={customerId}
                        className={customerId == null ? 'has-error' : ''}
                    />
                }
            />
            {customerId != null && customer != null ? <div>
                <InfoAttribute
                    attributeTitle={<Trans>Cenová hladina:</Trans>}
                    attributeValue={
                        <ChildPriceLevelSelect
                            customerId={customerId}
                            onChange={this.onPriceLevelChange}
                            allowClear={true}
                            value={priceLevelId}
                            allowSelf={true}
                        />
                    }
                />
                <InfoAttribute
                    attributeTitle={<Trans>Výrobca:</Trans>}
                    attributeValue={
                        <ManufacturersSelect
                            onChange={this.onManufacturerChange}
                            allowClear={true}
                            isMulti={true}
                            value={manufacturerId}
                            placeholder="všetko"
                        />
                    }
                />
                <InfoAttribute
                    attributeTitle={
                        <Tooltip title={<Trans>prijatá objednávka sa nevyskladní, kým nepríde celá objednaná položka od dodávateľa</Trans>}>
                            <Trans>Objednať v celku:</Trans>
                        </Tooltip>
                    }
                    attributeValue={
                        <Radio.Group 
                            value={useFullQuantity}
                            onChange={this.onUseFullQuantityChange}
                        >
                            <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        </Radio.Group>
                    }
                />
                <InfoAttribute
                    attributeTitle={<Trans>Dodací termín:</Trans>}
                    attributeValue={
                        <Radio.Group 
                            className="pt-1"
                            value={availableInAdvance}
                            onChange={this.onAvailAdvanceChange}
                        >
                            <Radio.Button value={null}><Trans>ASAP</Trans></Radio.Button>
                            <Radio.Button value={0}><Trans>Podľa prijatej obj.</Trans></Radio.Button>
                            <Radio.Button value={7}><Trans>Týždeň vopred</Trans></Radio.Button>
                        </Radio.Group>
                                
                    }
                />
                <CartOrderInfoPageForm
                    onChangeParams={this.onOrderParamsChange}
                    params={orderParams}
                    customerDetails={customer}
                    tradeIsFixed={true}
                    allowProxy={true}
                />
            </div> : null }
            <div className="text-center">
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <ButtonFetch
                    type="primary"
                    disabled={disabled}
                    values={{}}
                    Failed={generalFailedPC(t`Vytvorenie objednávky zlyhalo`)}
                    fetchFunction={createFetchOrderFromIncoming({
                        id: finalOrder.id,
                        id_customer: customerId,
                        id_price_level: priceLevelId,
                        id_manufacturer: manufacturerId,
                        use_full_quantity: useFullQuantity,
                        available_in_advance: availableInAdvance,
                        id_user: orderParams.userId,
                        final_order: orderParams.isProxy ? {
                            ext_id: finalOrder.id,
                            order_reference: orderParams.finalOrderRef,
                            customer: {
                                name: orderParams.finalCustomerName
                            },
                            user: {
                                fullname: orderParams.finalUserName,
                                e_mail: orderParams.finalUserEmail,
                                phone: orderParams.finalUserPhone,
                                id_language: orderParams.finalUserLanguage,
                            },
                            delivery_address: {
                                street: orderParams.finalAddressStreet,
                                zip_code: orderParams.finalAddressZipCode,
                                city: orderParams.finalAddressCity,
                                id_country: orderParams.finalAddressCountryId,
                            }
                        } : undefined,
                        id_invoice_address: orderParams.invoiceAddressId,
                        id_delivery_address: orderParams.deliveryAddressId,
                        id_transport_type: orderParams.transportTypeId,
                        id_payment_method: orderParams.paymentMethodId,
                        customer_reference: orderParams.customerReference,
                        customer_notices: orderParams.customerNotices,
                        dealer_notices: orderParams.dealerNotices,
                        is_divisible: orderParams.isDivisible,
                        is_critical: orderParams.isCritical,
                        id_dst_stock: orderParams.dstStockId,
                    })}
                    onFinish={onSubmitFinish}
                >
                    <Trans>Uložiť</Trans>
                </ButtonFetch>
            </div>
        </div>;
    }
}

const CreateOrderFormWrapped = withdataHOC([GLOBAL_DATA.FETCH_DATA])(CreateOrderForm);