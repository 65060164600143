import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import {getQueryParam, navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {isOrderSend, isTradeSell} from '../../../lib/project';
import {createFetchOrderSend} from '../../../backend/apiCalls';
import {Button, Form, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../../constants/Rights';
import { numberOrNull } from '../../../lib/number';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
const FormItem = Form.Item;

/**
 * @fero
 */


class SendOrder extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    onSuccess = () => {
        const {
            [GLOBAL_DATA.RELOAD_DATA]: reload, 
            [SESSION_ATTRIBUTES.SETTINGS]:settings,
            [SESSION_ATTRIBUTES.GTAG]: gtag, 
            location, orderDetails} = this.props;
        const reportToGoogle = getQueryParam(location, QUERY_PARAMS.REPORT_TO_GOOGLE);
        if(reportToGoogle) {
            gtag('event', 'purchase', {
                transaction_id: orderDetails.id,
                affiliation: settings.company_name,
                value: numberOrNull(orderDetails.sum_netto),
                currency: orderDetails.id_currency,
                tax: numberOrNull(orderDetails.sum_brutto) - numberOrNull(orderDetails.sum_netto),
                shipping: numberOrNull(orderDetails.transport_type ? orderDetails.transport_type.price : 0),
            });
            navigateToParametrized(location, null, {[QUERY_PARAMS.REPORT_TO_GOOGLE]: undefined});
        }
        reload([GLOBAL_DATA.ORDER_DETAILS]);
    };

    render() {
        const {orderDetails} = this.props;
        const isSent = isOrderSend(orderDetails);
        const isSelling = isTradeSell(orderDetails.trade);

        return <FormModal
            openNode={
                <React.Fragment>
                    <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                        <Button disabled={isSent} type="primary">
                            <Trans>Záväzne objednať</Trans>
                        </Button>
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <Button disabled={!isSelling && isSent} type="primary">
                            { isSelling ?
                                ( isSent ? 
                                    <Trans>Odoslať potvrdenie</Trans>
                                    :
                                    <Trans>Potvrdiť objednávku</Trans>
                                )
                                : <Trans>Záväzne objednať</Trans>
                            }
                        </Button>
                    </RightsWrapper>
                </React.Fragment>
            }
            values={{orderDetails}}
            onFinishSuccessful={this.onSuccess}
            title={<Trans>Odoslanie objednávky</Trans>}
            Form={SendOrderFormWrapped}
            Response={SendOrderResponse}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať objednávku.`)}
        />;
    }

}

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.SETTINGS, SESSION_ATTRIBUTES.GTAG])(
        withDataHOC([GLOBAL_DATA.RELOAD_DATA])(SendOrder)
    )
);

class SendOrderResponse extends React.PureComponent {
    render() {
        const {fetchResult, values} = this.props;
        const {orderDetails = {}} = values;
        return <div>
            <h4>{t`Objednávka bola úspešne aktivovaná`}</h4>
            { isTradeSell(orderDetails.trade) ? 
                ( fetchResult ?
                    <span>{t`Potvrdenie bolo zaslané na e-mail.`}</span>
                    :
                    <span className="color-red">{t`Potvrdenie NEBOLO odoslané.`}</span>
                )
                : null
            }
        </div>;
    }
}

class SendOrderForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderDetails: OrderDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchOrderSend()(formValues).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {orderDetails = {}} = values;
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;

        const isSelling = isTradeSell(orderDetails.trade);
        const isSent = isOrderSend(orderDetails);
        const customerChecked = orderDetails.customer != null ? orderDetails.customer : {};
        const userChecked = orderDetails.user != null ? orderDetails.user : {};
        const deliveryAddressChecked = orderDetails.delivery_address != null ? orderDetails.delivery_address : {};
        const transportTypeChecked = orderDetails.transport_type != null ? orderDetails.transport_type : {};
        const shipUser = (deliveryAddressChecked.contact || userChecked.fullname || null);
        const shipEmail = (deliveryAddressChecked.e_mail || userChecked.e_mail || null);
        const shipPhone = (deliveryAddressChecked.phone || userChecked.phone || null);

        {getFieldDecorator('id', {initialValue: orderDetails.id})}
        return <Form onSubmit={this.handleSubmit}>
            <h5 className="mb-3">     
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                    <Trans>Naozaj chcete záväzne odoslať objednávku?</Trans>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    { isSelling ? 
                        ( isSent ? 
                            <Trans>Naozaj chcete záväzne odoslať potvrdenie objednávky?</Trans> 
                            :
                            <Trans>Naozaj chcete záväzne potvrdiť objednávku?</Trans>
                        )
                        :
                        <Trans>Naozaj chcete záväzne odoslať objednávku?</Trans>
                    }
                    { orderDetails.customer_reference_duplicity ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Pre danú organizáciu už existuje objednávka s číslom</Trans>
                                {' '}
                                {orderDetails.customer_reference}
                                {'.'}
                            </div>
                        </div>:
                        null
                    }
                    {orderDetails.id_user != null && customerChecked.id_channel == null ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Pre danú organizáciu nie je nastavený komunikačný kanál.</Trans>
                            </div>
                        </div>:
                        null
                    }
                    { orderDetails.user == null ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Nie je nastavená kontakná osoba.</Trans>
                            </div>
                        </div>:
                        null
                    }
                    { isSelling && transportTypeChecked.courier_class != null && (shipUser == null || shipEmail == null || shipPhone == null) ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Nie je nastavená osoba preberajúca tovar, jej e-mail alebo telefónne číslo.</Trans>
                            </div>
                        </div>:
                        null
                    }
                    { isSelling && (orderDetails.id_stock == null || orderDetails.id_stock.length == 0) ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Nie sú nastavené sklady na vyskladnenie.</Trans>
                            </div>
                        </div>:
                        null
                    }
                </RightsWrapper>
            </h5>
            <RightsWrapper from={RIGHTS.MARKETING}>
                { isSelling && !isSent ? 
                    <FormItem 
                        {...formItemLayout}
                        label={<Trans>Odoslať potvrdenie</Trans>}
                    >
                        {getFieldDecorator('no_message', {initialValue: 0})(
                            <Radio.Group>
                                <Radio.Button value={0}><Trans>Áno</Trans></Radio.Button>
                                <Radio.Button value={1}><Trans>Nie</Trans></Radio.Button>
                            </Radio.Group>
                        )}
                    </FormItem>
                    : null
                }
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Odoslať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const SendOrderFormWrapped = Form.create()(SendOrderForm);