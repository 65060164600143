import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {createFetchOrderResetPrices} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class OrderResetPrices extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        orderId: PropTypes.number.isRequired,
    };

    render() {
        const {orderId, onFinish, disabled, ...props} = this.props;
        return <FormModal
            {...props}
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Resetovať ceny na hodnoty podľa cenníka</Trans>}>
                    <Button
                        disabled={disabled}
                        size="small"
                        icon="redo"
                    />
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={onFinish}
            title={<Trans>Reset cien na objednávke</Trans>}
            Form={ generalNoParameterFormPC(
                    <Trans>Naozaj chcete resetovať všetky ceny na objednávke na hodnoty podľa aktuálneho cenníka?</Trans>,
                    createFetchOrderResetPrices(orderId)
                )
            }
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa resetovať ceny na objednávke.`)}
        />;
    }

}

export default OrderResetPrices;

