import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import FormModal from '../../fetch/FormModal';
import {getDomain} from '../../../lib/url';
import generalFailedPC from '../../fetch/generalFailedPC';
import {GP_PAYMENT_TARGET} from '../../../constants/apiEndpoints';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {PAYMENT_METHOD} from '../../../constants/paymentMethods';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RedirectForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.first = React.createRef();
    }

    componentDidMount() {
        const buttonNode = ReactDOM.findDOMNode(this.first.current);
        setTimeout(() => {
            if(buttonNode.focus != null) {
                buttonNode.focus();
            }
        }, 0);
    }

    render() {
        const {onClose, onRedirect} = this.props;
        return <div>
            <h5><Trans>Chcete doplatiť objednávku?</Trans></h5>
            <div className="mt-3 d-flex justify-content-end">
                <Button onClick={onClose}>
                    <Trans>Nie</Trans>
                </Button>
                <Button ref={this.first}  onClick={onRedirect} type="primary">
                    <Trans>Áno</Trans>
                </Button>
            </div>
        </div>

    }
}

class PayRest extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
        className: PropTypes.string,
    };

    navigateToPaymentGateway = () => {
        const {orderDetails} = this.props;
        const url = getDomain() + GP_PAYMENT_TARGET + '?id_order=' + orderDetails.id;
        window.location.replace(url);
    };

    render() {
        const {className, orderDetails} = this.props;
        const priceToPay = orderDetails.sum_brutto - orderDetails.prepayment;
        const paymentMethod = orderDetails.payment_method != null ? orderDetails.payment_method : null;
        if(paymentMethod.id != PAYMENT_METHOD.GP_ONLINE_GATEWAY || priceToPay < 0.01)
            return null;

        return <div className={className}>
            <FormModal
                openNode={
                    <Button type="primary">
                        <Trans>Zaplatiť</Trans>
                    </Button>
                }
                values={{}}
                title={<Trans>Doplatenie objednávky</Trans>}
                Form={RedirectForm}
                onRedirect={this.navigateToPaymentGateway}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa presmerovať na platobnú bránu.`)}
            />
        </div>;
    }

}

export default PayRest;